<script>
import { VclCode } from 'vue-content-loading';
import { mapState } from "vuex";

export default {
  components: {
    VclCode
  },
  props: {

  },
  computed: {

  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/accounting/cashflow', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.data = data;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  },
  created() {
    this.getData()
    this.timer = setInterval(() => {this.getData();}, 1000 * 60);
  },
  destroyed() {
    if(this.timer) clearInterval(this.timer);
  },
  data() {
    return {
      ready: false,
      error: false,
      data: {},
      timer: null
    }
  }
};
</script>

<template>
  <div v-if="ready">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-body">
          <div class="row">
            <div class="col-lg-3 col-sm-12">
              <div class="d-flex align-items-center mb-3">
                <div class="avatar-xs me-3">
                    <span class="avatar-title rounded-circle bg-soft bg-danger text-black font-size-18 avatar-sm align-self-center mini-stat-icon">
                      <i class="fad fa-file-alt text-white"></i>
                    </span>
                </div>
                <h4 class="ml-3 mb-0">
                  Invoices
                  <div class="text-danger text-small font-size-14">
                    Due
                  </div>
                </h4>
              </div>
              <div class="text-muted mt-4">
                <h4>
                  {{ $n(data.invoices.outstanding.balance, {locale: 'de'}) }} €
                </h4>
                <div class="d-flex">
                  {{ $n(data.invoices.outstanding.count, {locale: 'de'}) }} invoices
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12">
              <div class="d-flex align-items-center mb-3">
                <div class="avatar-xs me-3">
                    <span class="avatar-title rounded-circle bg-soft bg-success text-info font-size-18 avatar-sm align-self-center mini-stat-icon">
                      <i class="fad fa-file-alt text-white"></i>
                    </span>
                </div>
                <h4 class="ml-3 mb-0">
                  Invoices
                  <div class="text-success text-small font-size-14">
                    Settled
                  </div>
                </h4>
              </div>
              <div class="text-muted mt-4">
                <h4>
                  {{ $n(data.invoices.transit.balance, {locale: 'de'}) }} €
                </h4>
                <div class="d-flex">
                  {{ $n(data.invoices.transit.count, {locale: 'de'}) }} invoices
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12">
              <div class="d-flex align-items-center mb-3">
                <div class="avatar-xs me-3">
                    <span class="avatar-title rounded-circle bg-soft bg-info text-info font-size-18 avatar-sm align-self-center mini-stat-icon">
                      <i class="fad fa-file-alt text-white"></i>
                    </span>
                </div>
                <h4 class="ml-3 mb-0">
                  Invoices
                  <div class="text-info text-small font-size-14">
                    Upcomming
                  </div>
                </h4>
              </div>
              <div class="text-muted mt-4">
                <h4>
                  {{ $n(data.invoices.upcomming.balance, {locale: 'de'}) }} €
                </h4>
                <div class="d-flex">
                  {{ $n(data.invoices.upcomming.count, {locale: 'de'}) }} invoices
                </div>
              </div>
            </div>
	          <div class="col-lg-3 col-sm-12">
		          <div class="d-flex align-items-center mb-3">
			          <div class="avatar-xs me-3">
                    <span class="avatar-title rounded-circle bg-soft bg-warning text-black font-size-18 avatar-sm align-self-center mini-stat-icon">
                      <i class="fad fa-money-bill text-black"></i>
                    </span>
			          </div>
			          <h4 class="ml-3 mb-0">
				          Revenue
				          <div class="text-warning text-small font-size-14">
					          Projected
				          </div>
			          </h4>
		          </div>
		          <div class="text-muted mt-4">
			          <h4>
				          {{ $n(data.invoices.projection.total, {locale: 'de'}) }} €
			          </h4>
			          <div class="d-flex">
				          {{ $n(data.invoices.projection.count, {locale: 'de'}) }} invoices
			          </div>
		          </div>
	          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="error">
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
            </h5>
          </div>
          <div class="card-body">
            <h5 class="card-title mt-0">
              {{ $t('dashboard.error.info') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <vcl-code :height="90"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
